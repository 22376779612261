<template>
  <v-container class="issued-app-container" data-testid="issued-app-container">
    <app-section-card
      v-for="(section, index) in sections"
      :key="index"
      :icon="section.icon"
      :title="section.title"
      :sections="section.sections"
    />
  </v-container>
</template>

<script>
import AppSectionCard from '@/components/application/AppSectionCard.vue'
import formatters from '@/helpers/formatters'
import {
  APPLICATION_OFFER_TYPE_NAME,
  CONTACT_PHONE_TYPE,
  DATASET,
  QR_ENROLMENT_TYPE
} from '@/constants'
import { mapGetters } from 'vuex'
import { ENROLMENT_TYPE } from '../../constants'

export default {
  name: 'IssuedApplication',
  components: {
    AppSectionCard
  },
  computed: {
    ...mapGetters(['application', 'defaultSelectionCriteria', 'referenceData']),
    sections() {
      return [
        {
          icon: 'mdi-account',
          title: 'Basic application details',
          sections: this.basicAppDetailsSections
        },
        {
          icon: 'mdi-home-outline',
          title: 'Residential address details',
          sections: this.residentialAddressSections
        },
        {
          icon: 'mdi-email-outline',
          title: 'Postal address details',
          sections: this.postalAddressSections
        },
        {
          icon: 'mdi-at',
          title: 'Parent/Carer contact details',
          sections: this.parentContactSections
        }
      ]
    },
    basicAppDetailsSections() {
      const { familyName, firstName, otherName, genderCode, dateOfBirth } =
        this.application?.student || {}
      const { enrolmentType, intendedStartDate, scholasticYear } =
        this.application

      return [
        this.createSection(
          'Student details',
          'Some basic details for the student that is being enrolled.',
          [
            this.createField('Family name', familyName),
            this.createField('Given name', firstName),
            this.createField('Middle name', otherName),
            this.createField('Gender', formatters.gender(genderCode)),
            this.createField('Date of birth', this.formatDate(dateOfBirth))
          ]
        ),
        this.createSection(
          'Enrolment details',
          'Initial enrolment details for the student.',
          [
            this.createField(
              'Enrolment type',
              this.getEnrolmentType(enrolmentType)
            ),
            this.createField(
              'Intended start date',
              this.formatDate(intendedStartDate)
            ),
            this.createField(
              'Scholastic year',
              scholasticYear === 'K' ? 'Kindergarten' : `Year ${scholasticYear}`
            )
          ]
        )
      ]
    },
    residentialAddressSections() {
      const address = this.application?.residentialAddress || {}
      const considerations = this.application?.considerations
      const {
        addressLine1,
        addressLine2,
        suburbName,
        stateCode,
        postCode,
        manuallyEnteredAddress
      } = address

      const residentialAddress = `${addressLine1}, ${suburbName} ${stateCode} ${postCode}`

      const getResidentialAddressSection = () => {
        if (manuallyEnteredAddress) {
          return this.createSection(
            'Student’s residential address',
            "The student's residential address was manually entered.",
            [
              this.createField('Address line 1', addressLine1),
              this.createField('Address line 2', addressLine2),
              this.createField('Suburb', suburbName),
              this.createField(
                'State',
                this.getValue(DATASET.STATES, stateCode)
              ),
              this.createField('Postcode', postCode)
            ]
          )
        }
        return this.createSection(null, null, [
          this.createField('Student’s residential address', residentialAddress)
        ])
      }
      const intakeAreaDescription = manuallyEnteredAddress
        ? 'As we could not automatically verify the address entered above, the intake area was manually specified.'
        : 'This is automatically determined based on the address entered above.'

      const residentialArr = [getResidentialAddressSection()]

      if (![ENROLMENT_TYPE.SHS].includes(this.application.enrolmentType)) {
        residentialArr.push(
          this.createSection('Intake area', intakeAreaDescription, [
            this.createField('Intake area', this.intakeArea)
          ])
        )
      }

      if (this.isOoaOffer && considerations) {
        const { primary, secondary, tertiary, otherConsiderations } =
          considerations

        residentialArr.push(
          this.createSection(
            'Out-of-area criteria',
            'Which criteria supported you to make your decision about this out-of-area application?',
            [
              this.createField(
                'Primary criteria',
                this.getCriteriaValue(primary)
              ),
              this.createField(
                'Secondary criteria',
                this.getCriteriaValue(secondary)
              ),
              this.createField(
                'Tertiary criteria',
                this.getCriteriaValue(tertiary)
              ),
              otherConsiderations
                ? this.createField('Other considerations', otherConsiderations)
                : null
            ].filter(Boolean)
          )
        )
      }
      return residentialArr
    },
    postalAddressSections() {
      const address = this.application?.correspondenceAddress || {}
      const {
        countryCode,
        addressLine1,
        addressLine2,
        suburbName,
        stateCode,
        postCode,
        useResidentialAddressAsPostal
      } = address
      if (useResidentialAddressAsPostal) {
        return [
          this.createSection(
            null,
            "Using the student's residential address as their postal address.",
            []
          )
        ]
      }

      return [
        this.createSection(
          'Student’s postal address',
          "The student's postal address is different to their residential address.",
          [
            this.createField(
              'Country',
              this.getValue(DATASET.COUNTRIES, countryCode)
            ),
            this.createField('Address line 1', addressLine1),
            this.createField('Address line 2', addressLine2),
            this.createField('Suburb/City', suburbName),
            this.createField(
              'State/Province',
              this.getValue(DATASET.STATES, stateCode) || stateCode
            ),
            this.createField('Postcode/Zip code', postCode)
          ]
        )
      ]
    },
    parentContactSections() {
      const parent = this.application?.parentCarers?.[0] || {}
      const phoneDetails = parent?.contactDetails?.find((contact) =>
        Object.keys(CONTACT_PHONE_TYPE).includes(contact.contactType)
      )

      return [
        this.createSection(
          'Contact details',
          'The parent/carer who was issued the enrolment application offer via email.',
          [
            this.createField('Family name', parent.parentCarerFamilyName),
            this.createField('Given name', parent.parentCarerGivenName),
            this.createField(
              'Relationship',
              this.getValue(DATASET.RELATIONSHIP, parent.parentCarerRelation)
            ),
            this.createField('Email address', this.application.emailAddress),
            this.createField(
              'Phone number type',
              CONTACT_PHONE_TYPE[phoneDetails?.contactType]
            ),
            this.createField('Phone number', phoneDetails?.contactValue)
          ]
        )
      ]
    },
    intakeArea() {
      if (this.application.isInArea === true) {
        return 'In area'
      }
      return 'Out-of-area'
    },
    applicationType() {
      const { isInArea } = this.application
      const { LOCAL_AREA_OFFER, OUT_OF_AREA_OFFER } =
        APPLICATION_OFFER_TYPE_NAME
      if (isInArea === false) {
        return OUT_OF_AREA_OFFER
      }
      return LOCAL_AREA_OFFER
    },
    isOoaOffer() {
      return (
        this.applicationType === APPLICATION_OFFER_TYPE_NAME.OUT_OF_AREA_OFFER
      )
    }
  },

  methods: {
    createSection(title, description, fields) {
      return { title, description, fields }
    },
    createField(label, value) {
      return { label, value }
    },
    formatDate(date) {
      return this.$moment(date)?.format('DD/MM/YYYY')
    },
    getCriteriaValue(code) {
      if (code === 'SBC') {
        return 'School is under buffer or cap and has capacity'
      }
      if (code === 'OTH') {
        return 'Other considerations'
      }
      return (
        this.defaultSelectionCriteria?.find(
          (criteria) => criteria.code === code
        )?.value || ''
      )
    },
    getValue(dataSetKey, code) {
      const referenceDataSet = this.referenceData(dataSetKey) || []
      return referenceDataSet.find((data) => data.value === code)?.text || ''
    },
    getEnrolmentType(type) {
      return QR_ENROLMENT_TYPE.find((data) => data.value === type)?.text || ''
    }
  }
}
</script>

<style scoped>
.issued-app-container {
  max-width: 800px;
  margin: 0 auto;
}
</style>
