
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.section {
  color: $ads-grey-01;
}

.section-card-title {
  font-weight: bold;
  color: $ads-primary-blue;
}

.section-card {
  padding: 36px 48px 12px;
  margin-bottom: 32px;

  @media print {
    box-shadow: none !important;
  }
}

.section-avatar {
  background-color: $ads-primary-teal;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

.section-field:not(:last-child) {
  margin-bottom: 38px;
}

.field-label {
  font-weight: bold;
  color: $ads-primary-blue;
  font-size: 14px;
}

.empty-value {
  opacity: 0.5;
}
